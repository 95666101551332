/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-dark-bg;
  font-family: $type-2;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    padding-bottom: 60px;
    .nav-item {
      .collapse {
        z-index: 999;
      }
      .collapse.show,
      .collapsing {
        background: $sidebar-dark-menu-active-bg;
      }
      .nav-link {
        align-items: center;
        display: flex;
        padding: $sidebar-menu-padding;
        white-space: nowrap;
        height: $nav-link-height;
        color: $sidebar-dark-menu-color;
        i {
          &.menu-arrow {
            margin-left: auto;
            margin-right: 0;
            @include transition-duration(0.2s);
            @include transition-property(transform);
            @include transition-timing-function(ease-in);
            &:before {
              content: "\F142";
              font-family: "Material Design Icons";
              font-size: 18px;
              line-height: 1;
              font-style: normal;
              vertical-align: middle;
              color: rgba($sidebar-dark-menu-color, 0.5);
            }
          }
        }
        &[aria-expanded="true"] {
          background: $sidebar-dark-menu-active-bg;
          i {
            &.menu-arrow {
              @include transform(rotate(90deg));
            }
          }
        }
        .menu-icon {
          margin-right: 1.25rem;
          width: $sidebar-icon-size;
          line-height: 1;
          font-size: 18px;
          color: lighten($sidebar-dark-menu-icon-color, 30%);
          .rtl & {
            margin-right: 0;
            margin-left: 1.25rem;
          }
        }
        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
        }
        .badge {
          margin-left: auto;
        }
        &:hover {
          color: darken($sidebar-dark-menu-color, 5%);
        }
      }
      &.active {
        >.nav-link {
          .menu-icon {
            color: $sidebar-dark-menu-active-color;
          }
          color: $sidebar-dark-menu-active-color;
        }
      }
      &.nav-profile {
        padding-bottom: 20px;
        img {
          @include border-radius(100%);
          margin: 20px auto 20px auto;
          width: 100%;
          max-width: 82px;
          display: block;
        }
        p {
          color: $sidebar-dark-profile-name-color;
        }
      }
    }
    &:not(.sub-menu) {
      >.nav-item {
        &:hover {
          &:not(.nav-profile) {
            >.nav-link {
              background: $sidebar-dark-menu-hover-bg;
              color: $sidebar-dark-menu-hover-color;
              .menu-icon {
                color: $sidebar-dark-submenu-hover-color;
              }
            }
          }
        }
      }
    }
    &.sub-menu {
      margin-bottom: 0;
      padding: $sidebar-submenu-padding;
      .nav-item {
        .nav-link {
          color: $sidebar-dark-submenu-color;
          padding: $sidebar-submenu-item-padding;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          &.active {
            color: $sidebar-dark-menu-active-color;
            background: transparent;
            &:before {
              background: $sidebar-dark-menu-active-color;
            }
          }
        }
        &:hover {
          >.nav-link {
            background: $sidebar-dark-submenu-hover-bg;
            color: $sidebar-dark-submenu-hover-color;
            &:before {
              background: $sidebar-dark-submenu-hover-color;
            }
          }
        }
      }
    }
  }
}

//sidebar color variation
.sidebar-light {
  .sidebar {
    background: $sidebar-light-bg;
    .nav {
      .nav-item {
        .collapse.show,
        .collapsing {
          background: $sidebar-light-menu-active-bg;
        }
        .nav-link {
          color: $sidebar-light-menu-color;
          &[aria-expanded="true"] {
            background: $sidebar-light-menu-active-bg;
          }
          i {
            color: $sidebar-light-menu-icon-color;
            &.menu-arrow {
              &:before {
                color: rgba($sidebar-light-menu-color, 0.5);
              }
            }
          }
          &:hover {
            color: darken($sidebar-light-menu-color, 5%);
          }
        }
        &.nav-profile {
          p {
            color: $sidebar-light-profile-name-color;
          }
        }
        &.active {
          >.nav-link {
            color: $sidebar-light-menu-active-color;
            i {
              color: $sidebar-light-menu-icon-color;
            }
          }
        }
        .sidebar-sticker {
          background: $sidebar-light-menu-active-bg;
        }
      }
      &:not(.sub-menu) {
        >.nav-item {
          &:hover {
            &:not(.nav-profile) {
              >.nav-link {
                background: $sidebar-light-menu-hover-bg;
                color: $sidebar-light-menu-hover-color;
                .menu-icon {
                  color: $sidebar-light-submenu-hover-color;
                }
              }
            }
          }
        }
      }
      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-light-submenu-color;
            &.active {
              color: $sidebar-light-menu-active-color;
              &:before {
                background: $sidebar-light-menu-active-color;
              }
            }
          }
          &:hover {
            >.nav-link {
              background: $sidebar-light-submenu-hover-bg;
              color: $sidebar-light-submenu-hover-color;
              &:before {
                background: $sidebar-light-submenu-hover-color;
              }
            }
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/

@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}