/* Navbar */

.navbar {
  &.default-layout {
    font-family: $type-2;
    background: $white;
    transition: background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: background $action-transition-duration $action-transition-timing-function;
    -moz-transition: background $action-transition-duration $action-transition-timing-function;
    -ms-transition: background $action-transition-duration $action-transition-timing-function;
    .navbar-brand-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      background: $sidebar-dark-bg;
      .sidebar-light & {
        background: darken($sidebar-light-bg, 10%);
      }
      width: $sidebar-width-lg;
      height: $navbar-height;
      .navbar-brand {
        color: $white;
        font-size: 1.5rem;
        line-height: 48px;
        margin-right: 0;
        padding: 0.25rem 0;
        @include display-flex;
        &:active,
        &:focus,
        &:hover {
          color: lighten(color(gray-dark), 10%);
        }
        img {
          width: calc(#{$sidebar-width-lg} - 130px);
          max-width: 100%;
          height: 28px;
          margin: auto;
          vertical-align: middle;
        }
      }
      .brand-logo-mini {
        display: none;
        img {
          width: calc(#{$sidebar-width-icon} - 50px);
          max-width: 100%;
          height: 28px;
          margin: auto;
        }
      }
    }
    .navbar-menu-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      color: $white;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - #{$sidebar-width-lg});
      height: $navbar-height;
      @media (max-width: 991px) {
        width: auto;
      }
      .navbar-toggler {
        border: 0;
        color: $black;
        &:not(.navbar-toggler-right) {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }
      .navbar-text {
        font-size: $default-font-size;
      }
      .navbar-nav {
        flex-direction: row;
        align-items: center;
        .nav-item {
          margin-left: 1rem;
          margin-right: 1rem;
          &.dropdown {
            .dropdown-toggle {
              font-weight: 700;
            }
            @media (max-width: 480px) {
              position: static;
            }
          }
          .nav-link {
            color: $text-muted;
            font-size: $navbar-font-size;
            vertical-align: middle;
            @media (max-width: 767px) {
              margin-left: 0.5rem;
              margin-right: 0.5rem;
            }
            i {
              font-size: $navbar-icon-font-size;
              vertical-align: middle;
            }
            .profile-text {
              margin-right: 15px;
              .rtl & {
                margin-right: 0;
                margin-left: 15px;
              }
            }
            &.nav-btn {
              .btn {
                background: rgba($white, .1);
                padding: 0.75rem 1rem;
                color: $white;
              }
              &:after {
                display: none;
              }
            }
          }
          &.user-dropdown {
            height: $navbar-height;
            border-right: 1px solid $border-color;
            border-left: 1px solid $border-color;
            margin: 0;
            padding-left: 1rem;
            padding-right: 1rem;
            .nav-link {
              padding-bottom: 0;
              padding-top: 0;
              &:after {
                display: none;
              }
              .dropdown-toggle-wrapper {
                height: $navbar-height;
                display: flex;
                >.inner {
                  display: flex;
                  @include align-items(center);
                  img {
                    display: block;
                  }
                  .profile-text {
                    display: block;
                    text-align: left;
                    padding-left: 10px;
                    &:not(.small) {
                      margin-bottom: 5px;
                    }
                    .rtl & {
                      padding-left: 0px;
                      padding-right: 10px;
                    }
                  }
                }
                .inner {
                  .icon-wrapper {
                    width: 20px;
                    height: 20px;
                    @include border-radius(100%);
                    border: 1px solid $border-color;
                    i {
                      font-size: 1rem;
                      line-height: 1.2rem;
                      display: block;
                      text-align: center;
                      margin: auto;
                    }
                  }
                }
              }
            }
          }
          &.search-wrapper {
            .input-group-prepend {
              .input-group-text {
                border: none;
                padding: 0;
                color: $text-muted;
              }
            }
            .input-group-prepend,
            .form-control {
              border: none;
              max-width: 80px;
              background: transparent;
              color: $text-muted;
              font-weight: 700;
              @include input-placeholder {
                font-weight: inherit;
                color: inherit;
              }
            }
          }
        }
        &.navbar-nav-right {
          @media (min-width: 992px) {
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
    } //navbar color variations
    &.navbar-primary {
      background: theme-color(primary);
      .navbar-menu-wrapper {
        .navbar-toggler {
          color: $white;
        }
        .navbar-nav {
          .nav-item {
            &.user-dropdown {
              border-right-color: darken(theme-color(primary), 10%);
              border-left-color: darken(theme-color(primary), 10%);
              .dropdown-toggle {
                .dropdown-toggle-wrapper {
                  .icon-wrapper {
                    border-color: darken(theme-color(primary), 10%);
                  }
                }
              }
            }
            &.search-wrapper {
              .input-group-prepend {
                .input-group-text {
                  color: $white;
                }
              }
              .input-group-prepend,
              .form-control {
                color: $white;
              }
            }
            .nav-link {
              color: $white;
              >* {
                border-color: darken(theme-color(primary), 20%);
              }
              &.count-indicator {
                .count {
                  background: theme-color(danger);
                }
              }
            }
          }
        }
      }
    }
    &.navbar-warning {
      background: theme-color(warning);
      .navbar-menu-wrapper {
        .navbar-toggler {
          color: $white;
        }
        .navbar-nav {
          .nav-item {
            &.user-dropdown {
              border-right-color: darken(theme-color(warning), 10%);
              border-left-color: darken(theme-color(warning), 10%);
              .dropdown-toggle {
                .dropdown-toggle-wrapper {
                  .icon-wrapper {
                    border-color: darken(theme-color(warning), 10%);
                  }
                }
              }
            }
            &.search-wrapper {
              .input-group-prepend {
                .input-group-text {
                  color: $white;
                }
              }
              .input-group-prepend,
              .form-control {
                color: $white;
              }
            }
            .nav-link {
              color: $white;
              >* {
                border-color: darken(theme-color(warning), 20%);
              }
              &.count-indicator {
                .count {
                  background: theme-color(danger);
                }
              }
            }
          }
        }
      }
    }
    &.navbar-success {
      background: theme-color(success);
      .navbar-menu-wrapper {
        .navbar-toggler {
          color: $white;
        }
        .navbar-nav {
          .nav-item {
            &.user-dropdown {
              border-right-color: darken(theme-color(success), 5%);
              border-left-color: darken(theme-color(success), 5%);
              .dropdown-toggle {
                .dropdown-toggle-wrapper {
                  .icon-wrapper {
                    border-color: darken(theme-color(success), 5%);
                  }
                }
              }
            }
            &.search-wrapper {
              .input-group-prepend {
                .input-group-text {
                  color: $white;
                }
              }
              .input-group-prepend,
              .form-control {
                color: $white;
              }
            }
            .nav-link {
              color: $white;
              >* {
                border-color: darken(theme-color(success), 20%);
              }
              &.count-indicator {
                .count {
                  background: theme-color(danger);
                }
              }
            }
          }
        }
      }
    }
    &.navbar-danger {
      background: theme-color(danger);
      .navbar-menu-wrapper {
        .navbar-toggler {
          color: $white;
        }
        .navbar-nav {
          .nav-item {
            &.user-dropdown {
              border-right-color: darken(theme-color(danger), 10%);
              border-left-color: darken(theme-color(danger), 10%);
              .dropdown-toggle {
                .dropdown-toggle-wrapper {
                  .icon-wrapper {
                    border-color: darken(theme-color(danger), 10%);
                  }
                }
              }
            }
            &.search-wrapper {
              .input-group-prepend {
                .input-group-text {
                  color: $white;
                }
              }
              .input-group-prepend,
              .form-control {
                color: $white;
              }
            }
            .nav-link {
              color: $white;
              >* {
                border-color: darken(theme-color(danger), 20%);
              }
              &.count-indicator {
                .count {
                  background: darken(theme-color(danger), 30%);
                }
              }
            }
          }
        }
      }
    }
    &.navbar-info {
      background: theme-color(info);
      .navbar-menu-wrapper {
        .navbar-toggler {
          color: $white;
        }
        .navbar-nav {
          .nav-item {
            &.user-dropdown {
              border-right-color: darken(theme-color(info), 10%);
              border-left-color: darken(theme-color(info), 10%);
              .dropdown-toggle {
                .dropdown-toggle-wrapper {
                  .icon-wrapper {
                    border-color: darken(theme-color(info), 10%);
                  }
                }
              }
            }
            &.search-wrapper {
              .input-group-prepend {
                .input-group-text {
                  color: $white;
                }
              }
              .input-group-prepend,
              .form-control {
                color: $white;
              }
            }
            .nav-link {
              color: $white;
              >* {
                border-color: darken(theme-color(info), 20%);
              }
              &.count-indicator {
                .count {
                  background: theme-color(danger);
                }
              }
            }
          }
        }
      }
    }
    &.navbar-dark {
      background: theme-color(dark);
      .navbar-menu-wrapper {
        .navbar-toggler {
          color: $white;
        }
        .navbar-nav {
          .nav-item {
            &.user-dropdown {
              border-right-color: lighten(theme-color(dark), 10%);
              border-left-color: lighten(theme-color(dark), 10%);
              .dropdown-toggle {
                .dropdown-toggle-wrapper {
                  .icon-wrapper {
                    border-color: lighten(theme-color(dark), 10%);
                  }
                }
              }
            }
            &.search-wrapper {
              .input-group-prepend {
                .input-group-text {
                  color: $white;
                }
              }
              .input-group-prepend,
              .form-control {
                color: $white;
              }
            }
            .nav-link {
              color: $white;
              >* {
                border-color: darken(theme-color(dark), 20%);
              }
              &.count-indicator {
                .count {
                  background: theme-color(danger);
                }
              }
            }
          }
        }
      }
    }
    &.navbar-pink {
      background: color(pink);
      .navbar-menu-wrapper {
        .navbar-toggler {
          color: $white;
        }
        .navbar-nav {
          .nav-item {
            &.user-dropdown {
              border-right-color: darken(color(pink), 10%);
              border-left-color: darken(color(pink), 10%);
              .dropdown-toggle {
                .dropdown-toggle-wrapper {
                  .icon-wrapper {
                    border-color: darken(color(pink), 10%);
                  }
                }
              }
            }
            &.search-wrapper {
              .input-group-prepend {
                .input-group-text {
                  color: $white;
                }
              }
              .input-group-prepend,
              .form-control {
                color: $white;
              }
            }
            .nav-link {
              color: $white;
              >* {
                border-color: darken(color(pink), 20%);
              }
              &.count-indicator {
                .count {
                  background: darken(color(pink), 30%);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:991px) {
  .navbar {
    &.default-layout {
      flex-direction: row;
      .navbar-brand-wrapper {
        width: 75px;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }
  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}