// Pagination variations
@mixin pagination-variants($color) {
  .page-item {
    .page-link {
      color: $color;
      border-color: $border-color;
    }
    &.active {
      .page-link {
        background: $color;
        border-color: $border-color;
      }
    }
    .page-link {
      &:hover {
        background: lighten($color, 5%);
        border-color: $color;
        color: $white;
      }
    }
  }
}