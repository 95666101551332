/* Dashboard */

#sales-status {
  margin-top: 10px;
}

#sales-status-legend {
  display: block;
  ul {
    list-style-type: none;
    @include display-flex;
    margin-top: 12px;
    margin-bottom: 0;
    li {
      @include display-flex;
      @include justify-content(center);
      @include align-items(center);
      margin-bottom: 5px;
      width: 50%;
      .chart-legend {
        height: 10px;
        width: 10px;
        border-width: 3px;
        border-style: solid;
        display: inline-block;
        margin-right: 10px;
        @include border-radius(100%);
        .rtl & {
          margin-right: 0px;
          margin-left: 10px;
        }
      }
      .chart-legend-label-text {
        width: 100px;
      }
    }
  }
}

#product-sales-legend {
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    li {
      display: inline-block;
      margin-right: 20px;
      .chart-color {
        height: 10px;
        width: 10px;
        border-style: solid;
        border-width: 2px;
        display: inline-block;
        margin-right: 5px;
        @include border-radius(100%);
        .rtl & {
          margin-right: 0px;
          margin-left: 5px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .rtl & {
        margin-right: 0px;
        margin-left: 20px;
        &:last-child {
          margin-left: 0px;
        }
      }
    }
  }
}

#dashboard-sales-chart {
  height: 150px;
  @include display-flex;
  svg {
    margin-top: auto;
  }
}

#morris-line-example {
  height: 200px;
}

.dashboard-news-card {
  position: relative;
  background-image: linear-gradient(315deg, rgba(theme-color(dark), 0.8), rgba(theme-color(info), 0.8), rgba(theme-color(success), 0.8));
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: block;
    background: url("https://www.placehold.it/362x407") no-repeat center center;
    background-size: cover;
  }
  h4 {
    padding-bottom: 30px;
    margin-bottom: 10px;
  }
}

.support-pane-card {
  .support-pane {
    .t-row {
      border-top: 1px solid $border-color;
      padding: 15px;
      @include display-flex;
      >.tumb {
        width: 7%;
        min-width: 70px;
        @include display-flex;
        @include align-items(center);
        font-weight: 700;
      }
      >.content {
        width: 63%;
        min-width: 400px;
        p {
          @include ellipsis;
          max-width: 90%;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      >.tile {
        width: 15%;
        min-width: 150px;
        p {
          @include ellipsis;
          max-width: 90%;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.card-statistics {
  .card {
    .card-tile {
      @include media-breakpoint-up(sm) {
        border-right: 1px solid $border-color;
      }
      &:last-child {
        border-right: none;
      }
      @include media-breakpoint-down(sm) {
        border-bottom: 1px solid $border-color;
        &:nth-child(2) {
          border-right: none;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}