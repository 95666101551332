/* Miscellanoeous */

body,
html {
  overflow-x: hidden; // resets padding right added by Bootstrap modal
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;
  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: 1.5rem 1.7rem;
  width: 100%;
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
}

.scroll-container {
  position: relative;
  &.horizontally {
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
  }
  &.vertically {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
  }
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}